<template>
  <v-container class="text-center">
    <v-container class="mt-10">
      <h1 class="display-2 font-weight-bold mb-8">
        Welcome to your Todo List
      </h1>
    </v-container>
    <p v-if="todos.length === 0">
      You don't appear to have any tasks
    </p>
    <p v-else-if="todos.find(v => !v.done)">
      You have completed {{ todos.reduce((acc,v) => acc + !!v.done, 0)}} of {{todos.length}} {{todos.length !== 1 ? 'tasks': 'task'}}
    </p>
    <p v-else>
      You have competed all of your tasks. Great job 🎉
    </p>

    <v-container class="mt-10">
      <v-text-field
        v-model="newTodo.text"
        label="Add new Item"
        append-outer-icon="mdi-plus"
        @keydown.enter= "addTodo()"
        @click:append-outer = "addTodo()"
      >

      </v-text-field>
      {{newTodo.text}}
      <v-card
        v-for="item in todos.filter(item => !item.done)"
        :key="item.text"
        class="my-2"
        @click="item.done =! item.done"
      >
        <v-card-title>
          <h4>{{item.text}}</h4>
        </v-card-title>

      </v-card>
    </v-container>


    <v-container>
      <v-card
          v-for="item in todos.filter(item => item.done)"
          :key="item.text"
          class="my-2"
          color="green"
      >
        <v-card-title>
          <h4>{{item.text}}</h4>
        </v-card-title>
        <v-card-actions>
          <v-btn
              height="40px"
              icon
              width="40px"
              @click.stop="todos = todos.filter(v => v.text !== item.text)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>

      </v-card>

      <v-btn
          :disabled="!todos.length"
          class="ma-2 mt-10 pa-6 white--text"
          color="red"
          @click = "clearTodos"
      >
        Delete All
        <v-icon right>mdi-delete</v-icon>

      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
  const todoTemplate = {text: '', done: false}

  export default {
    name: 'HelloWorld',

    data: () => ({
      todos: [{text: 'wuäh', done: true}],
      newTodo: Object.assign({}, todoTemplate),
    }),
    methods: {
      addTodo() {
        if (!this.newTodo.text) return

        this.todos.push(this.newTodo)
        this.newTodo = Object.assign({}, todoTemplate)
      },
      clearTodos() {
        this.todos = []
      },
      saveState() {
        localStorage.setItem("todos", JSON.stringify(this.todos))
      },
    },
    watch: {
      todos: {
        handler() {
          this.saveState()
        },
        deep: true
      }
    },
    mounted() {
      if (localStorage.todos)
        this.todos = JSON.parse(localStorage.todos)
    }
  }
</script>
