import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const mq = window.matchMedia('(prefers-color-scheme:dark)')

const vuetify = new Vuetify({
    theme: { dark: mq.matches }
})
mq.addEventListener('change', e => {
    vuetify.framework.theme.dark = e.matches
})
export default vuetify